var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app"},[_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.miniDesktop,"permanent":!_vm.$vuetify.breakpoint.xs && !_vm.$vuetify.breakpoint.sm,"fixed":"","right":_vm.$vuetify.rtl ? true : _vm.isMobile,"width":_vm.isMobile
      ? '80%'
      : _vm.userType == 'clinic'
      ? _vm.sideOpen
        ? '15%'
        : '70px'
      : '15%',"color":"register-primary"},on:{"update:miniVariant":function($event){_vm.miniDesktop=$event},"update:mini-variant":function($event){_vm.miniDesktop=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"text-center px-5 py-2 mt-4 mb-2 d-flex align-center w-100",class:_vm.sideOpen ? ' justify-space-around' : ' justify-center'},[(_vm.sideOpen || _vm.userType == 'doctor')?_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":"/"}},[_c('img',{staticClass:"object-contain",attrs:{"src":require('@/assets/images/logo-white.png'),"width":_vm.isMobile ? '55%' : '90%',"alt":""}})]):_vm._e(),(_vm.isMobile)?_c('img',{staticClass:"cursor-pointer",attrs:{"width":"22px","src":require('@/assets/images/dashboard/icons/close.svg')},on:{"click":function($event){_vm.drawer = !_vm.drawer}}}):_vm._e()],1),_c('v-list',{staticClass:"px-2",attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"green-active","dark":"","color":"green-active"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},[_vm._l((_vm.tabs),function(tab,i){return [(tab.route)?_c('router-link',{key:i,staticClass:"mb-5 d-flex align-center space-between",attrs:{"to":tab.to,"tag":"div"}},[_c('v-list-item',{staticClass:"d-flex align-center justify-space-between w-100",attrs:{"active-class":"green-active"}},[_c('div',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"tab-img",attrs:{"width":"22px","height":"25px","src":tab.img,"alt":""}}),(_vm.userType == 'doctor' || _vm.sideOpen)?_c('span',{staticClass:"font-18 font-500 white--text mx-4"},[_vm._v(_vm._s(tab.title))]):_vm._e()]),(
                tab.count &&
                tab.title == 'Notifications' &&
                _vm.notificationsCount > 0
              )?_c('span',{staticClass:"count-cover"},[_vm._v(_vm._s(_vm.notificationsCount))]):_vm._e()])],1):_c('v-list-group',{key:i,staticClass:"mb-3",attrs:{"no-action":"","append-icon":`mdi-chevron-${_vm.$vuetify.rtl ? 'left' : 'right'}`,"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('img',{staticClass:"tab-img",attrs:{"src":tab.img,"alt":""}}),_c('span',{staticClass:"font-18 font-500 white--text mx-4"},[_vm._v(_vm._s(tab.title))])]},proxy:true}],null,true)},_vm._l((tab.children),function(subTab,idx){return _c('router-link',{key:idx,attrs:{"to":subTab.to}},[_c('v-list-item',{attrs:{"color":"white"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"pl-5 white--text",domProps:{"textContent":_vm._s(subTab.title)}})],1)],1)],1)}),1)]})],2)],1),_c('v-list-item',{staticClass:"d-flex align-center",on:{"click":_vm.logout}},[_c('img',{staticClass:"tab-img",attrs:{"width":"26px","src":require('@/assets/images/icons/logout.svg'),"alt":""}}),_c('span',{staticClass:"font-18 font-500 white--text d-inline-block px-4"},[_vm._v(_vm._s(_vm.$t("forms.logout")))])])],1),_c('v-main',{staticClass:"ml-auto greybg over-scroll-x",class:_vm.sideOpen || _vm.userType == 'doctor' ? '' : 'v-main--mini'},[_c('navbar-app',{on:{"toggleMenu":function($event){_vm.drawer = true}}}),(_vm.profile.has_sign || _vm.userType !='doctor')?_c('div',{staticClass:"content"},[_c('router-view')],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }