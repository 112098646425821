<template>
    <div>
        <dashboard-desktop-navbar :links="links" v-if="!isMd"></dashboard-desktop-navbar>
        <dashboard-mobile-drawer :links="links" v-if="isMd"></dashboard-mobile-drawer>
    </div>
</template>

<script>


import DashboardMobileDrawer from "@/views/dashboard/layout/DashboardMobileDrawer.vue";
import DashboardDesktopNavbar from "@/views/dashboard/layout/DashboardDesktopNavbar.vue";

export default {
    components: {DashboardDesktopNavbar, DashboardMobileDrawer},
    data: (vm) => ({
        links: [
            {title: vm.$t("navbar.home"), route: "/", active: false, select: false, name: 'Home',},
            {title: vm.$t("navbar.aboutUs"), route: "/about", active: false, select: false, name: 'About',},
            {
                title: vm.$t("navbar.howItWorks"),
                route: "/how-it-works",
                active: false,
                select: true,
                list: [
                    {
                        name: vm.$t("navbar.institutions"),
                        route: "/medical-institutions",
                    },
                    {
                        name: vm.$t("navbar.professionals"),
                        route: "/medical-professionals",
                    },
                ],
            },
            {title: vm.$t("navbar.countries"), route: "/countries", active: false, select: false, name: 'Countries',},
            // { title: vm.$t("navbar.blogs"), route: "/blogs", active: false, select: false, name: 'Blogs', },
            {title: vm.$t("navbar.contactUs"), route: "/contact", active: false, select: false, name: 'Contact',},
        ],
    }),
    methods: {},
};
</script>

<style lang="scss">
</style>
