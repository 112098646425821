<template>
    <div class="row">
        <div class="col-12">
            <div
                    v-if="role == 'doctor' && !profile.is_complete_profile"
                    class="complete-profile text-center register-primary d-flex align-center justify-center"
                    :class="profile.is_complete_profile ? 'complete-profile--hide' : ''"
                    style=" background-image: linear-gradient(to right, #EA4C46 , #F07470);">
                <div>
                   <span class="font-16 white--text d-inline-block mr-3 complete-profile__link cursor-pointer"
                         @click="$router.push('/complete-profile/get-started')">
                       {{ $t("forms.completeProfileHint") }}
                   </span>
                </div>
            </div>
        </div>
        <div class="col-12">
            <dashboard-navbar>

            </dashboard-navbar>
        </div>

    </div>

</template>

<script>
import {mapGetters} from "vuex";
import DashboardNavbar from "./DashboardNavbar.vue";

export default {
    components: {DashboardNavbar},
    data: () => ({data: {}, hideProfileBar: false}),
    computed: {
        ...mapGetters(["profile"]),
        role() {
            return localStorage.getItem("role");
        },
        typeImage() {
            if (this.role == 'clinic') {
                return require('@/assets/images/general/hospital.jpg')
            } else {
                if (this.profile.gender == 'male') {
                    return require('@/assets/images/general/doctor.png')
                } else {
                    return require('@/assets/images/general/doctor_female.jpg')
                }
            }
        }
    },
    async created() {
        await this.$store.dispatch("setProfile");
    },
};
</script>

<style lang="scss">
.navbar-dashboard {
  &__icon {
    transition: 0.3s;
    background: #f8faff;
    border-radius: 5px;
    height: 40px;
    width: 40px;

    &:hover {
      background: $bordercolor;
    }
  }

  &__profile {
    background: #f8faff;
    border-radius: 5px;
  }
}

.complete-profile {
  height: 50px;
  width: auto;
  box-shadow: 0px 17px 31px 0px #87e5d52a;

  &__link {
    text-decoration: underline;
  }

  @media (max-width: 960px) {
    width: 95%;
    margin: auto;
    animation: unset !important;
  }
}
</style>


<!--<div-->
<!--    class="d-flex align-center justify-space-between px-3 py-2"-->
<!--    v-if="isMobile"-->
<!--&gt;-->
<!--<router-link to="/">-->
<!--    <img width="140px" :src="require('@/assets/images/logo.png')"/>-->
<!--</router-link>-->
<!--<img-->
<!--        @click="$emit('toggleMenu')"-->
<!--        :src="require('@/assets/images/dashboard/icons/menu-black.svg')"-->
<!--/>-->
<!--</div>-->
<!--<div v-if="isMobile && role == 'doctor'">-->
<!--&lt;!&ndash; v-if="role == 'doctor' && profile." &ndash;&gt;-->
<!--<div-->
<!--        class="complete-profile text-center register-primary d-flex align-center justify-center"-->
<!--        :class="profile.is_complete_profile ? 'complete-profile&#45;&#45;hide' : ''"-->
<!--&gt;-->
<!--    <div>-->
<!--          <span-->
<!--                  class="font-16 white&#45;&#45;text d-inline-block mr-3 complete-profile__link cursor-pointer"-->
<!--                  @click="$router.push('/complete-profile/get-started')"-->
<!--          >{{ $t("forms.completeProfileHint") }}</span-->
<!--          >-->
<!--    </div>-->
<!--</div>-->
<!--</div>-->
