<template>
    <div class="navbar" style="z-index: 0">
        <div class="py-1 px-8">
            <div class="d-flex align-center justify-space-between">
                <div class="d-flex align-center">
                    <!-- links -->
                    <div class="d-flex align-center justify-start">
                        <div v-for="(link, i) in links" :key="i">
                            <router-link v-if="!link.select" :to="link.route">
                             <span class="nav-link darktext--text font-16 d-inline-block mx-3"
                                   :class="link.name == $route.name ? 'nav-link--active' : ''">{{ link.title }}</span>
                            </router-link>

                            <v-menu v-if="link.select" v-model="openMenu" bottom offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                            v-bind="attrs"
                                            v-on="on"
                                            class="nav-link d-inline-flex cursor-pointer navlinks"
                                            @click="openMenu = true"
                                            :class="$route.name == 'Medical Institutions'
                                            || $route.name == 'Medical Professionals' ? 'nav-link--active' : ''">
                                        <span class="font-16 font-qs-m ls-1">{{ link.title }}</span>
                                        <img width="18px" :src="require('@/assets/images/icons/arrow-down.svg')"/>
                                    </div>
                                </template>

                                <v-list class="register-primary">
                                    <v-list-item v-for="(item, index) in link.list" :key="index">
                                        <router-link
                                                :to="item.route"
                                                tag="div"
                                                class="cursor-pointer"
                                        >
                                            <span class="white--text font-16">{{ item.name }}</span>
                                        </router-link>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>
                </div>

                <!-- buttons -->
                <div class="d-flex align-center">
                    <v-menu offset-y left v-model="linkActive">
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                class="d-flex align-center cursor-pointer lang-btn"
                                :class="$vuetify.rtl ?'ml-3' : 'mr-3'"
                                v-bind="attrs"
                                v-on="on">
                                <img
                                    class="lang-img"
                                    width="24px"
                                    height="24px"
                                    :src=" require(`@/assets/images/${$i18n.locale == 'en' ? 'en' : 'ar'}.svg`)"/>

                                    <span class="font-14 font-500 d-inline-block ml-2 mr-3">{{
                                        $i18n.locale == "en" ? "English" : "العربية"
                                      }}</span>

                                <img width="20px" :src="require('@/assets/images/icons/arrow-down.svg')"/>
                            </div>
                        </template>
                        <v-list class="px-2 list-lang">
                            <div
                                class="d-flex align-center cursor-pointer"
                                @click="switchLanguage"
                            >
                                <img
                                    class="lang-img"
                                    width="24px"
                                    height="24px"
                                    :src="
                    require(`@/assets/images/${
                      $i18n.locale == 'en' ? 'ar' : 'en'
                    }.svg`)
                  "
                                />
                                <span class="font-14 font-500 d-inline-block ml-2 mr-3">{{
                                    $i18n.locale == "en" ? "العربية" : "English"

                                  }}</span>
                            </div>
                        </v-list>
                    </v-menu>
                    <div
                            class="navbar-dashboard__profile px-1 d-flex align-center justify-space-between pr-4">
                        <img
                                class="object-cover"
                                width="40px"
                                height="40px"
                                :src="typeImage"
                                alt=""
                        />

                        <div class="mx-4">
                            <div class="d-flex" v-if="profile.specialization">
                                <span class="font-13 lightgreytext--text d-block">
                                    {{ profile.specialization.slice(0, 30) }}</span>
                                <span class="font-13 lightgreytext--text d-block"
                                      v-if="profile.specialization.length >= 1"
                                >...</span>
                            </div>

                            <div>
                                <span class="font-15" v-if="role == 'doctor' && profile.first_name">
                                    {{ profile.first_name }} {{ profile.last_name }}
                                </span>
                            </div>
                            <span class="font-16 font-600 d-block" v-if="role == 'clinic' && profile.name">
                                {{ profile.name }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        links: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        data: {},
        linkActive: false,
        openMenu: false,
        hideProfileBar: false
    }),
    computed: {
        ...mapGetters(["profile"]),
        role() {
            return localStorage.getItem("role");
        },
        typeImage() {
            if (this.role == 'clinic') {
                return require('@/assets/images/general/hospital.jpg')
            } else {
                if (this.profile.gender == 'male') {
                    return require('@/assets/images/general/doctor.png')
                } else {
                    return require('@/assets/images/general/doctor_female.jpg')
                }
            }
        },
        token() {
            return localStorage.getItem("token");
        },
    },
    methods: {
        logoutHandler() {
            this.$store.dispatch("logout");
        },
        switchLanguage() {
            if (this.$i18n.locale == "en") {
                this.$vuetify.rtl = true;
                this.$i18n.locale = "ar";
                localStorage.setItem("language", "ar");
            } else {
                this.$vuetify.rtl = false;
                this.$i18n.locale = "en";
                localStorage.setItem("language", "en");
            }
            this.$router.go();
        },
    },
    async created() {
        await this.$store.dispatch("setProfile");
    },
};
</script>

<style lang="scss">
.navbar {
  position: relative;
  z-index: 999;

  .lang-img {
    border-radius: 50%;
  }

  .nav-link {
    border-bottom: 2px solid transparent;
    transition: 0.3s;

    &--active {
      border-bottom: 2px solid $register-primary;
    }
  }
  .lang-btn{
    border-radius: 6px;
    background: rgba($register-primary, 0.08);
    padding: 10px;
    transition: 0.3s;
    
    &:hover{
      background: rgba($register-primary, 0.28);
      
    }
  }
}
.list-lang{
    background: rgba($register-primary, 0.08) !important;
    transition: 0.3s !important;
      
    &:hover{
      background: rgba($register-primary, 0.28) !important;
      
    }
  }
.v-list-item {
  min-height: 35px !important;
}
</style>
