<template>
    <div class="drawer">
        <v-app-bar
                height="65"
                prominent
                class="w-100 d-flex align-center justify-space-between"
                elevation="0"
        >
            <v-app-bar-nav-icon
                    style="z-index: 999999"
                    @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>

            <router-link to="/">
                <img
                        class="cursor-pointer"
                        width="150px"
                        :src="require('@/assets/images/logo.png')"
                        alt="logo"
                />
            </router-link>
        </v-app-bar>
        <v-navigation-drawer
                v-model="drawer"
                fixed
                :right="$i18n.locale == 'en'"
                :left="$i18n.locale != 'en'"
                temporary
                color="register-primary"
                height="100vh"
        >
            <v-list nav dense>
                <v-list-item-group v-model="group">
                    <div class="d-flex justify-end text-right mb-1">
                        <v-icon @click="drawer = false" color="white">mdi-close</v-icon>
                    </div>

                    <div class="d-flex align-center justify-start mt-5 mb-5">
                        <div
                                class="d-flex align-center cursor-pointer  px-2"
                                @click="switchLanguage"
                        >
                       
                            <img
                                    class="lang-img"
                                    width="24px"
                                    height="24px"
                                    :src="
                  require(`@/assets/images/${
                    $i18n.locale == 'en' ? 'ar' : 'en'
                  }.svg`)
                "
                            />
                            <span class="font-14 font-500 d-inline-block ml-2 mr-3 white--text">{{
                                $i18n.locale == "en" ? "العربية" : "English"
                              }}</span>
                        </div>
                    </div>

                    <!-- <v-btn
                      outlined
                      color="white"
                      elevation="0"
                      height="40px"
                      block
                      class="radius-10 hover-btn mt-3"
                      @click="$router.push('/register')"
                    >
                      <span class="white--text font-700 font-18">{{$t('navbar.createAccount')}}</span>
                    </v-btn> -->

                    <div class="text-left mb-2" v-for="(link, i) in links" :key="i">
                        <router-link v-if="!link.select" :to="link.route">
                            <v-list-item>
                                <v-list-item-title class="white--text">{{
                                    link.title
                                    }}
                                </v-list-item-title>
                            </v-list-item>
                        </router-link>
                        <div v-if="link.select">
                            <v-list-group no-action sub-group>
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title class="white--text">{{
                                            link.title
                                            }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </template>

                                <router-link
                                        v-for="(subList, i) in link.list"
                                        :key="i"
                                        tag="div"
                                        :to="subList.route"
                                >
                                    <v-list-item class="px-7">
                                        <v-list-item-title
                                                class="white--text"
                                                style="opacity: 0.8"
                                                v-text="subList.name"
                                        ></v-list-item-title>
                                    </v-list-item>
                                </router-link>
                            </v-list-group>
                        </div>
                    </div>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    props: {
        links: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        drawer: false,
        group: "",
    }),
    methods: {
        switchLanguage() {
            if (this.$i18n.locale == "en") {
                this.$vuetify.rtl = true;
                this.$i18n.locale = "ar";
                localStorage.setItem("language", "ar");
            } else {
                this.$vuetify.rtl = false;
                this.$i18n.locale = "en";
                localStorage.setItem("language", "en");
            }
            this.$router.go();
        },
    },
};
</script>

<style lang="scss">
.v-toolbar__content {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100%;
}

.drawer {
  z-index: 9999999999999999999 !important;
  position: relative;

  .logo {
    width: 110px !important;
  }

  .v-list {
    background: transparent !important;

    i {
      color: white;
    }
  }

  .v-list-group__header {
    padding: 0px 8px !important;

    i {
      color: white !important;
    }
  }
}
</style>
