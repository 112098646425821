<template>
    <div class="app">
        <!-- drawer -->
        <v-navigation-drawer
                v-model="drawer"
                :mini-variant.sync="miniDesktop"
                :permanent="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
                fixed
                :right="$vuetify.rtl ? true : isMobile"
                :width="
        isMobile
          ? '80%'
          : userType == 'clinic'
          ? sideOpen
            ? '15%'
            : '70px'
          : '15%'
      "
                color="register-primary"
        >
            <div
                    class="text-center px-5 py-2 mt-4 mb-2 d-flex align-center w-100"
                    :class="sideOpen ? ' justify-space-around' : ' justify-center'"
            >
                <router-link
                        to="/"
                        v-if="sideOpen || userType == 'doctor'"
                        class="d-flex align-center"
                >
                    <img
                            class="object-contain"
                            :src="require('@/assets/images/logo-white.png')"
                            :width="isMobile ? '55%' : '90%'"
                            alt=""
                    />
                </router-link>
                <img
                        v-if="isMobile"
                        @click="drawer = !drawer"
                        class="cursor-pointer"
                        width="22px"
                        :src="require('@/assets/images/dashboard/icons/close.svg')"
                />
            </div>

            <v-list dense class="px-2">
                <v-list-item-group
                        active-class="green-active"
                        v-model="selectedItem"
                        dark
                        color="green-active"
                >
                    <template v-for="(tab, i) in tabs">
                        <router-link
                                v-if="tab.route"
                                :to="tab.to"
                                tag="div"
                                :key="i"
                                class="mb-5 d-flex align-center space-between"
                        >
                            <v-list-item
                                    active-class="green-active"
                                    class="d-flex align-center justify-space-between w-100"
                            >
                                <div class="d-flex align-center">
                                    <img width="22px" height="25px" class="tab-img" :src="tab.img" alt=""/>
                                    <span
                                            class="font-18 font-500 white--text mx-4"
                                            v-if="userType == 'doctor' || sideOpen"
                                    >{{ tab.title }}</span
                                    >
                                </div>
                                <span
                                        class="count-cover"
                                        v-if="
                    tab.count &&
                    tab.title == 'Notifications' &&
                    notificationsCount > 0
                  "
                                >{{ notificationsCount }}</span
                                >
                            </v-list-item>
                        </router-link>

                        <!-- group -->
                        <v-list-group
                                v-else
                                no-action
                                class="mb-3"
                                :key="i"
                                :append-icon="`mdi-chevron-${$vuetify.rtl ? 'left' : 'right'}`"
                                color="white"
                        >
                            <template v-slot:activator>
                                <img class="tab-img" :src="tab.img" alt=""/>
                                <span class="font-18 font-500 white--text mx-4">{{
                                    tab.title
                                    }}</span>
                            </template>
                            <router-link
                                    v-for="(subTab, idx) in tab.children"
                                    :key="idx"
                                    :to="subTab.to"
                            >
                                <v-list-item color="white">
                                    <v-list-item-content>
                                        <v-list-item-title
                                                class="pl-5 white--text"
                                                v-text="subTab.title"
                                        ></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </router-link>
                        </v-list-group>
                    </template>
                </v-list-item-group>
            </v-list>

            <v-list-item class="d-flex align-center " @click="logout">
                <img
                        width="26px"
                        class="tab-img"
                        :src="require('@/assets/images/icons/logout.svg')"
                        alt=""
                />
                <span class="font-18 font-500 white--text d-inline-block px-4">{{
                    $t("forms.logout")
                    }}</span>
            </v-list-item>
        </v-navigation-drawer>

        <!-- content -->
        <v-main
                class="ml-auto greybg over-scroll-x"
                :class="sideOpen || userType == 'doctor' ? '' : 'v-main--mini'"
        >
            <navbar-app @toggleMenu="drawer = true"></navbar-app>
            <div class="content" v-if="profile.has_sign || userType !='doctor'">
                <router-view/>
            </div>
        </v-main>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import NavbarApp from "./Navbar";

export default {
    components: {
        NavbarApp,
    },
    data: (vm) => ({
        drawer: false,
        mini: false,
        selectedItem: null,
        doctorTabs: [
            {
                title: vm.$t("dashboard.profile"),
                key: 'profile',
                img: require("@/assets/images/dashboard/icons/user.svg"),
                to: "/dashboard/profile",
                route: false,
                children: [
                    {
                        title: vm.$t("side.personalInfo"),
                        to: "/dashboard/profile/info",
                    },
                    {
                        title: vm.$t("side.degrees"),
                        to: "/dashboard/profile/degrees",
                    },
                    {
                        title: vm.$t("side.jobExperience"),
                        to: "/dashboard/profile/experiences",
                    },
                    {
                        title: vm.$t("side.socialMedia"),
                        to: "/dashboard/profile/social-medias",
                    },
                ],
            },
            {
                title: vm.$t("dashboard.interviews"),
                key: 'interviews',
                img: require("@/assets/images/dashboard/icons/hand.svg"),
                to: "/dashboard/interviews",
                route: true,
            },
            {
                title: vm.$t("dashboard.neededDocument"),
                key: 'neededDocument',
                img: require("@/assets/images/dashboard/icons/document.svg"),
                to: "/dashboard/documents",
                route: true,
            },
            {
                title: vm.$t("dashboard.chats"),
                key: 'chats',
                img: require("@/assets/images/dashboard/icons/document.svg"),
                to: "/dashboard/chats",
                route: true,
            },
            {
                title: vm.$t("dashboard.notifications"),
                key: 'notifications',
                img: require("@/assets/images/dashboard/icons/notification.svg"),
                to: "/dashboard/notifications",
                route: true,
            },
        ],
        clinicTabs: [
            {
                title: vm.$t("dashboard.profile"),
                key: 'profile',
                img: require("@/assets/images/dashboard/icons/clinic-user.svg"),
                to: "/dashboard/profile/info",
                count: false,
                route: true,
            },
            {
                title: vm.$t("dashboard.requests"),
                key: 'requests',
                img: require("@/assets/images/dashboard/icons/box.svg"),
                to: "/dashboard/orders",
                count: false,
                route: true,
            },
            {
                title: vm.$t("dashboard.interviews"),
                key: 'interviews',
                img: require("@/assets/images/dashboard/icons/hand.svg"),
                to: "/dashboard/interviews",
                count: false,
                route: true,
            },
            {
                title: vm.$t("dashboard.ourDoctors"),
                key: 'ourDoctors',
                img: require("@/assets/images/dashboard/icons/our-doctors.svg"),
                to: "/dashboard/our-doctors",
                count: false,
                route: true,
            },

            {
                title: vm.$t("dashboard.notifications"),
                key: 'notifications',
                img: require("@/assets/images/dashboard/icons/notification.svg"),
                to: "/dashboard/notifications",
                count: true,
                route: true,
            },

        ],
        sideOpen: true,
        notificationsCount: 0,
    }),
    computed: {
        ...mapGetters(["userType", "profile"]),
        user() {
            return localStorage.getItem("user");
        },
        darkMood() {
            return this.$store.getters["darkMood"];
        },
        miniDesktop() {
            if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.xs) {
                return false;
            } else {
                return this.mini;
            }
        },
        tabs() {
            if (this.userType == "doctor") {
                if (this.profile) {
                    if (this.profile.status == 4 || this.profile.status == 5) {
                        return this.doctorTabs;
                    } else {
                        return this.doctorTabs.filter(
                            (tab) => tab.key != "neededDocument"
                        );
                    }
                } else {
                    return [];
                }
            } else {
                return this.clinicTabs;
            }
        },
    },
    methods: {
        logout() {
            this.$store.dispatch("logout");
        },
        async getNotifications() {
            let res;
            if (this.userType == "doctor") {
                res = await this.$axios.get("doctor/data/counts");
            } else {
                res = await this.$axios.get("hospital/data/counts");
            }

            if (res.data) {
                this.notificationsCount = res.data.data.notifications_counts;
            }
        },
    },
    async created() {
        await this.getNotifications();
    },
};
</script>

<style lang="scss">
.app {
  background: #f8faff !important;
  overflow-x: hidden;

  .v-navigation-drawer--mini-variant {
    width: 0px !important;
  }

  .side-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .v-main {
    width: calc(100% - 15%);

    .content {
      min-height: 100vh;
      width: 100%;
      padding: 20px;
    }

    &--mini {
      width: calc(100% - 70px);
    }
  }

  .main-mini {
    width: 100%;
  }

  .count-cover {
    background: white;
    width: 25px;
    height: 25px;
    color: $darkprimary;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .v-list-item:after {
    display: none;
  }

  @media (max-width: 960px) {
    .v-main {
      width: 100% !important;

      .content {
        padding: 0px;
      }
    }
  }
}

.v-list-group--active
> .v-list-group__header
> .v-list-group__header__append-icon
.v-icon {
  transform: rotate(90deg) !important;
}

.v-list-group--active {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
}

.v-list-item--link:before,
.v-list-item--active {
  border-radius: 5px !important;
}
</style>
